import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import TreatmentHero from "../components/treatment-hero";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { Button } from "react-bootstrap";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-html-parser";

const FinancePage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "dental-treatment-finance" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        dentalTreatmentFinancePageFieldGroups {
          dentalTreatmentFinanceIntroSection {
            heading
            subHeading
            title
            content
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          dentalTreatmentFinanceTextSectionWithCta {
            heading
            content
            cta {
              title
              target
              url
            }
          }
          dentalTreatmentFinanceTextSection {
            heading
            content
          }
          dentalTreatmentFinanceTermsSection {
            heading
            content
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, dentalTreatmentFinancePageFieldGroups },
  } = data;

  const {
    dentalTreatmentFinanceIntroSection,
    dentalTreatmentFinanceTextSectionWithCta,
    dentalTreatmentFinanceTextSection,
    dentalTreatmentFinanceTermsSection,
  } = dentalTreatmentFinancePageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Dental Treatment Finance",
        item: {
          url: `${siteUrl}/dental-treatment-finance`,
          id: `${siteUrl}/dental-treatment-finance`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/dental-treatment-finance`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {dentalTreatmentFinanceIntroSection &&
          !checkPropertiesForNull(dentalTreatmentFinanceIntroSection, [
            "heading",
          ]) && (
            <TreatmentHero
              objPos="right bottom"
              heading1={dentalTreatmentFinanceIntroSection?.title}
              noSmallHeading
              img={
                dentalTreatmentFinanceIntroSection?.image.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imgAlt={dentalTreatmentFinanceIntroSection?.image.node?.altText}
              heading2={dentalTreatmentFinanceIntroSection?.heading}
              headingSmall={dentalTreatmentFinanceIntroSection?.subHeading}
              text={
                <SafeHtmlParser
                  htmlContent={dentalTreatmentFinanceIntroSection?.content}
                />
              }
              btnLink={dentalTreatmentFinanceIntroSection?.cta.url}
              btnText={dentalTreatmentFinanceIntroSection?.cta?.title}
            />
          )}

        {dentalTreatmentFinanceTextSectionWithCta &&
          !checkPropertiesForNull(dentalTreatmentFinanceTextSectionWithCta, [
            "heading",
          ]) && (
            <section className="py-5 py-lg-7">
              <Container>
                <Row id="info">
                  <Col className="">
                    <h2 className="  fs-1 mb-5 ">
                      {dentalTreatmentFinanceTextSectionWithCta?.heading}
                    </h2>
                    <SafeHtmlParser
                      htmlContent={
                        dentalTreatmentFinanceTextSectionWithCta?.content
                      }
                    />
                  </Col>
                </Row>
                {dentalTreatmentFinanceTextSectionWithCta.cta &&
                  dentalTreatmentFinanceTextSectionWithCta.cta.url && (
                    <Row>
                      <Col>
                        <div className="text-center">
                          <Button
                            className="text-white mt-5 mt-lg-6 py-3 px-5 w-100 w-md-auto"
                            variant="accent"
                            href={
                              dentalTreatmentFinanceTextSectionWithCta.cta?.url
                            }
                            target={
                              dentalTreatmentFinanceTextSectionWithCta.cta
                                ?.target || "_blank"
                            }
                            rel="noreferrer"
                          >
                            {
                              dentalTreatmentFinanceTextSectionWithCta.cta
                                ?.title
                            }
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
              </Container>
            </section>
          )}
        {dentalTreatmentFinanceTermsSection &&
          !checkPropertiesForNull(dentalTreatmentFinanceTermsSection, [
            "heading",
          ]) && (
            <section className="pb-5 pb-lg-7">
              <Container>
                <Row>
                  <Col className="text-center">
                    <h2 className="  fs-1 mb-5 ">
                      {dentalTreatmentFinanceTermsSection?.heading}
                    </h2>
                    <SafeHtmlParser
                      htmlContent={dentalTreatmentFinanceTermsSection?.content}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {dentalTreatmentFinanceTextSection &&
          !checkPropertiesForNull(dentalTreatmentFinanceTextSection, [
            "heading",
          ]) && (
            <section className="bg-primary py-5 py-lg-7">
              <Container>
                <Row>
                  <Col className="text-center">
                    <h2 className=" text-white fs-1 mb-5 ">
                      {dentalTreatmentFinanceTextSection?.heading}
                    </h2>
                    <div className="text-white">
                      <SafeHtmlParser
                        htmlContent={dentalTreatmentFinanceTextSection?.content}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}
      </Layout>
    </>
  );
};

export default FinancePage;
